import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/Generic.jsx";
import Badge from "../../components/_ui/Badge.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Pergola Planting: Annual Climbers in Pots`}</h1>
    <span className="text-metadata">Posted on 03/08</span>
    <Badge mdxType="Badge" />
    <img src="/img/outdoor-living/pergola-planting-annual-climbers-in-pots/morning-glory.jpg" alt="The intertwined vines and purple flowers of a Morning Glory (Ipomoea Purpurea)" />
    <p><strong parentName="p">{`I’m the first to admit that when I think of lushly planted pergolas, it normally involves the big-hitting perennial climbers like clematis, vines and honeysuckle. With initial care and attention and a bit of yearly maintenance, they’ll reward you year after year with their leafy coverage and show of flowers or fruit.`}</strong></p>
    <p>{`But why limit yourself to these alone? Vigorous climbing annuals give you the flexibility to create colourful displays - exactly where you want them - in a single season. They are particularly useful for softening the structure of new pergolas quickly with foliage and flowers.`}</p>
    <img src="/img/outdoor-living/pergola-planting-annual-climbers-in-pots/purple-bell-vine.jpg" alt="A purple bell vine (Rhodochiton atrosanguineus AGM*)" />
    <h2>{`What annual climbers can I grow?`}</h2>
    <p><strong parentName="p">{`Take your pick from some of the most popular annual climbers:`}</strong></p>
    <ul>
      <li parentName="ul">{`morning glory (Ipomoea Purpurea)`}</li>
      <li parentName="ul">{`purple bell vine (Rhodochiton atrosanguineus AGM`}{`*`}{`)`}</li>
      <li parentName="ul">{`black-eyed Susan (Thunbergia alata)`}</li>
      <li parentName="ul">{`cup-and-saucer vine (cobaea scandens AGM)`}</li>
      <li parentName="ul">{`sweet peas (lathyrus odoratus)`}</li>
      <li parentName="ul">{`climbing nasturtium (Tropaeolum majus)`}</li>
      <li parentName="ul">{`canary creeper (T. peregrinum)`}</li>
    </ul>
    <p><em parentName="p">{`*`}{`AGM - the RHS Award of Garden Merit, awarded by the RHS to a plant trialled at an RHS garden over a period of time. It’s a recommendation that it’s easy to grow and a great performer. If it’s good enough for the RHS, it’s good enough for us!`}</em></p>
    <img src="/img/outdoor-living/pergola-planting-annual-climbers-in-pots/black-eyed-susan.jpg" alt="The yellow, pale yellow, and white flowers of a black-eyed Susan (Thunbergia alata)" />
    <p>{`For full descriptions of these plants, use the `}<a parentName="p" {...{
        "href": "http://apps.rhs.org.uk/rhsplantfinder/"
      }}>{`RHS Plant Finder`}</a></p>
    <h2>{`What you can do now`}</h2>
    <p>{`Early March is the perfect time to sow seeds of annual climbers, but don‘t leave it too much longer. Many of the annual climbers take a while to get going, so you need to sow early to avoid disappointment later. Alternatively, buy young plants when you’re ready to plant up your pots.`}</p>
    <p>{`Sow your seeds individually into small pots and place in a warm propagator to give them a head start. After germination, wait until they’re big enough to prick out and grow on in fresh compost, and they should be ready to plant into their final pots in May.`}</p>
    <img src="/img/outdoor-living/pergola-planting-annual-climbers-in-pots/cupandsaucervine-rhs.jpg" alt="The purple flowers of a cup-and-saucer vine (cobaea scandens AGM)" />
    <h2>{`How to grow annual climbers up your pergola`}</h2>
    <p>{`Choose a loam-based compost like John Innes no 2 - it holds water efficiently, preventing your climbers from drying out too quickly. They’ll need plenty of water when they’re in growth, so be nice and don’t let them dry out. Show them some love by feeding them every couple of weeks. They’ll reward you for months on end - many will keep on going right up to the first frosts.`}</p>
    <p>{`Once the threat of frost has passed, place your pots close to the upright supports of your pergola to allow them to scramble upwards. Make sure they’re on a sunny side of the pergola. These babies love the sun. If you hide them in the shade they’re liable to sulk.`}</p>
    <img src="/img/outdoor-living/pergola-planting-annual-climbers-in-pots/sweet-pea-flowerpictures2.org.jpg" alt="The pink flowers of a sweet pea (lathyrus odoratus)" />
    <p>{`Most annual climbers twine using tendrils, so give them some help with the aid of cut-to-fit trellis, plastic netting or - even easier - vertical wire held by vine eyes. For a really natural look use pea sticks. When I prune dead wood from trees, shrubs and bushes, I keep some back for plant supports all around the garden. They’re ideal for supporting annual climbers.`}</p>
    <p>{`If your pergola planting is well-established, you can forgo these additional supports and let your annual climbers weave their way up host plants like perennial clematis or vines.`}</p>
    <img src="/img/outdoor-living/pergola-planting-annual-climbers-in-pots/climbing-nasturtium2.jpg" alt="A climbing nasturtium (Tropaeolum majus), with it's red flowers, climbing up a wall" />
    <h2>{`How to get the most out of your annual climbers`}</h2>
    <p>{`If you’ve got the time or inclination, pinch out the tips of new growth during the growing season - this will create a better shape and multiply the flowering shoots. If you don’t do it, it’ll still look fabulous. And come next March, you can choose a whole new batch of climbing annuals for a different colour scheme or planting effect. Ring in the changes!`}</p>
    <img src="/img/outdoor-living/pergola-planting-annual-climbers-in-pots/canary-creeper-suttons.jpg" alt="A canary creeper (T. peregrinum)" />
    <h4>{`Image credits`}</h4>
    <ul>
      <li parentName="ul"><small>Black-eyed Susan www.swallowtailgardenseeds.com</small></li>
      <li parentName="ul"><small>Climbing nasturtium www.johnstowngardencentre.ie</small></li>
      <li parentName="ul"><small>Cup-and-Saucer vine www.Rhs.org.uk</small></li>
      <li parentName="ul"><small>Sweet Pea www.flowerpictures.org</small></li>
      <li parentName="ul"><small>Purple Bell vine www.gardenworldimages.com</small></li>
      <li parentName="ul"><small>Morning Glory www.greatflowers.blogspot.com</small></li>
      <li parentName="ul"><small>Canary Creeper: www.suttons.co.uk</small></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      